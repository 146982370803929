<template>
  <div>
    <TitleMore
      :title="['市州红十字会']"
      :isMore="false"
    ></TitleMore>
    <div class="baseCard donateBottom">
      <p style="
font-size: 20px;
font-weight: bold;
color: #555454;
line-height: 48px;
text-align:center">四川省及各市（州）红十字会咨询电话</p>
      <TitleLine width="200px"></TitleLine>
      <el-table
        :data="tableData"
        stripe
        border="true"
        style="width: 100%;margin-top:30px"
        :show-header="false"
      >
        <el-table-column
          style="background:red"
          prop="title"
          label="姓名"
        >
        </el-table-column>

        <el-table-column
          prop="address"
          label="地址"
        >
          <template slot-scope="scope">
            <div style="display:flex;align-items:center">
              <!-- <img
                src="../../assets/img/address.png"
                alt=""
              > -->
              <i class="iconfont icon-dizhi" style="color: #5da8ee; fontSize: 20px"></i>
              <span style="margin-left:10px">{{scope.row.address}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="link_phone"
          label="电话"
          width="200"
        >
          <template slot-scope="scope">
            <div style="display:flex;align-items:center">
              <!-- <img
                src="../../assets/img/phone.png"
                alt=""
              > -->
              <i class="iconfont icon-dianhua" style="color:#3ac8a2; fontSize: 25px"></i>
              <span style="margin-left:10px">{{scope.row.link_phone}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top:40px"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-size="size"
        :total="total"
        layout="total, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      size:10,
      total: 0,
    };
  },

  components: {
    TitleMore,
    TitleLine,
  },

  mounted() {
    this.getList();
  },

  methods: {
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    getList() {
      apiUrls
        .selfDynamicList({ node: "sxhh", page: this.page - 1, size: this.size })
        .then((res) => {
          this.tableData = res.results.data;
          this.total = res.results.pager.totalElements;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.donateBottom {
  /deep/.el-table th,
  .el-table tr {
    background-color: #f4e2c8;
    color: #ae6d23;
    font-size: 18px;
    font-weight: bold;
  }
}
</style>